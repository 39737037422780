
import React from 'react'
import Link from 'gatsby-link'
import logo from '../images/mk_zirkel.png'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

class Navi extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <Navbar color="dark" dark expand="md">

        <NavbarBrand href="/">
          <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="MK Zirkel" />
        </NavbarBrand>

        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/" activeClassName="active" exact>Startseite</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/mitgliedsbuende" activeClassName="active">Mitgliedsbünde</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/der-mk" activeClassName="active">Der MK auf einen Blick</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/satzung" activeClassName="active">Satzung</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/impressum" activeClassName="active">Impressum</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>

    )
  }
}

export default Navi
