import React, { Component } from 'react'

import Layout from '../components/layout'
import wappen from '../images/mk_wappen.png'
import sport from '../images/sport.jpg'
import kommers from '../images/kommers.jpg'
import fahnen from '../images/fahnen.jpg'
import liederheft from '../images/liederheft.jpg'

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from 'reactstrap';

const items = [
  {
    src: sport,
    altText: 'Sport',
    header: 'Sport',
    caption: `Der gemeinsame Sport genießt im Marburger Konvent eine hohe Priorität: Bei jedem Treffen, die der Dachverband organisiert,
    stellen die Wettkämpfe den Mittel- und Höhepunkt dar. In dieser Ausrichtung kommt die Überzeugung des Mitgliedsbünde
    zum Ausdruck, dass in ein gesunder Geist auch einen gesunden Körper braucht („sana mens in corpore sano“).`,
    cssCaptionClasses: 'carousel-caption text-left'
  },
  {
    src: kommers,
    altText: 'Kommers',
    header: 'Gemeinschaft',
    caption: `So wie die Gemeinschaft den Kern und Zweck jeder studentischen Verbindung darstellt, ist sie natürlich auch
    auf der Verbandsebene ein zentraler Wert. Um diesen Wert zu pflegen, veranstaltet der MK jährlich drei Dachverbandsveranstaltungen:
    Eines zu Pfingsten, eins im November und eines im April. Diese bieten neben dem Erfahrungsaustausch und den
    sportlichen Wettkämpfen durch ihre geselligen Teile immer auch die Möglichkeit, bundesweit neue Bekanntschaften
    zu schließen und alte zu pflegen.`,
    cssCaptionClasses: 'carousel-caption'
  },
  {
    src: liederheft,
    altText: 'Liederheft',
    header: 'Pfingsttreffen',
    caption: `Ein Mal im Jahr treffen wir uns in Hann. Münden zu unserem Pfingsttreffen zu einem gemeinsamen Kommers, einer
    Verbandsversammlung und zu umfangreichen sportlichen Wettkämpfen.`,
    cssCaptionClasses: 'carousel-caption text-right'
  }
];


class IndexPage extends Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }



  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} alt={item.altText} />
          <div className={item.cssCaptionClasses}>
            <h1>{item.header}</h1>
            <p>
              {item.caption}
            </p>
          </div>
        </CarouselItem>
      );
    });

    return (
      <Layout>
        <section className="hero">
          <div className="container">
            <img src={wappen} alt="Wappen" />
            <h1 className="text-bold">Marburger Konvent studentischer Verbindungen</h1>
          </div>
        </section>

        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>

        <div className="container marketing">

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-md-7">
              <h2 className="featurette-heading">Willkommen beim&nbsp;
            <span className="text-muted">Marburger Konvent</span>
              </h2>
              <p>Herzlich willkommen auf der Internetpräsenz des Marburger Konvents studentischer Verbindungen („MK“). Auf den folgenden
                Seiten können Sie sich über das Wesen und die Struktur des MKs informieren: Unter dem Register „Mitgliedsbünde“
                stellen sich die Mitgliedsbünde vor. Unter dem Stichwort „Der MK auf einen Blick“ finden Sie eine tabellarische
                Übersicht über die wichtigsten Daten des MKs. Unter dem Punkt „Satzung“ findet sich die aktuelle gültige Satzung
                des MKs.
          </p>
            </div>
            <div className="col-md-5">
              <img className="featurette-image img-fluid mx-auto" src={fahnen} alt="MK-Fahnen" />
            </div>
          </div>

          <hr className="featurette-divider" />
        </div>

      </Layout>
    )
  }
}

export default IndexPage
